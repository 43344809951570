import http from "../http-common";

const getAll = (parametros) => {
  if(parametros) return http.get('/recetas?page='+parametros.page+'&size='+parametros.size+'&sort='+parametros.sort);
  return http.get('/recetas');
};
const get = id => {
  return http.get("/recetas/"+id);
};

const create = data => {
  return http.post("/recetas/", data);
};

const update = (id, data) => {
  return http.put('/recetas/'+id, data);
};

const remove = id => {
  return http.delete('/recetas/'+id);
};


//const findByTitle = title => {
//  return http.get(`/tutorials?title=${title}`);
//};

const RecetaService = {
    getAll,
    get,
    create,
    update,
    remove
};

export default RecetaService;