export default function ComponenteReceta(props) {
    const url = props.value.foto;
    const seleccion = (e) => {
        if(props.onSeleccion) {props.onSeleccion(e.target.id)}
      }
    return (
        <div>  
            <div class="tarjeta">
                <div class="tarjeta_interior">
                    <div class="foto_tarjeta" style={{ 
                        backgroundImage: `url(${url})` 
                        }}>
                    </div>
                    <div class="pie_tarjeta">
                        <h3>{props.value.nombre}</h3>
						<div class="tarjeta_pie_textos">
							<p class="porciones">{props.value.porciones} porciones</p>
							<p class="carbohidratos">{props.value.carbohidratos} carbohidratos</p>
						</div>
						<div class="tarjeta_pie_boton">
							<button class="boton_tarjeta" onClick={seleccion} id={props.value.idReceta}>Ver mas</button>
						</div>
                    </div>
                </div>
            </div>
        </div>
    );
}