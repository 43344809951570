import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import {BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
import { PaginaDescripcion } from './paginas/descripcion';
import { PaginaRecetas } from './paginas/recetas';

export default function App() {
  function MiLink(item,options,url){
    return (
      <Link to={url} className={options.className} >
        <span className= {`p-menuitem-icon ${item.icon}`}/>
        <span className={options.labelClassName}>{item.label}</span>
      </Link>
    );
  }
  const items = [
		{
		    label: 'Recetas',
		    icon: 'pi pi-fw pi-home',
        template: (item, options) => MiLink(item,options,'/')
		 }
	];
  const end = <Button label="Login" />

  return (
    <PrimeReactProvider>
        <div className="card">  
          <Router>
            <Menubar model={items} end={end}/>
            <Routes>
              <Route path="/" element={<PaginaRecetas />} />
            </Routes>
          </Router>
        </div>
    </PrimeReactProvider>
  );
}

