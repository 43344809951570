import axios from "axios";

export default axios.create({
  
  //baseURL: "http://di.unla.edu.ar/servers-back/public"
  baseURL:process.env.REACT_APP_API_URL
  /*headers: {
    "Content-type": "application/json"
  }*/
});
