import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import React, { useState, useEffect } from 'react';
import RecetaService from 'services/recetas_service';
import ComponenteReceta from "componentes/receta";
import DialogReceta from "componentes/dialog_receta";

export function PaginaRecetas() {
    const [recetaElegida, setRecetaElegida] = useState([]);
    const [recetas, setRecetas] = useState([]);
    const [visible, setVisible] = useState(false);
    const [pagina, setPagina] = useState({
        page: 0,
        size: 4,
        sort: "idReceta,desc"
      });
    const [totalPaginas, setTotalPaginas] = useState(0);
    const mas = () => {
        cambiar_pagina(1);
    }
    const menos = () => {
        cambiar_pagina(-1);
    }
    const cambiar_pagina = (sentido) => {
        let aux = pagina.page;
        if(sentido === 1) aux = aux+1;
        else aux = aux-1;
        if(aux === -1) aux = totalPaginas-1;
        if(aux === totalPaginas) aux = 0;
        setPagina({...pagina, page: aux}); 
        RecetaService.getAll({...pagina, page: aux})
        .then(response => {
            setRecetas(response.data.content);
            setTotalPaginas(response.data.totalPages);
        })
        .catch(e => {
            console.log(e);
        });
      }
    const seleccionar = (idReceta) => {
        RecetaService.get(idReceta)
        .then(response => {
            setRecetaElegida(response.data);
            console.log(response.data);
        })
        .catch(e => {
        console.log(e);
        });
        setVisible(true);
    }

    useEffect(() => {
        RecetaService.getAll(pagina)
        .then(response => {
            setRecetas(response.data.content);
            setTotalPaginas(response.data.totalPages);
        })
        .catch(e => {
          console.log(e);
        });  
        RecetaService.get(1)
        .then(response => {
            setRecetaElegida(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    }, []);
  
  return (
    <div>
        <div class="contenedor_botonera">
            <div class="botoneras">
                <button class="boton_navegacion pi pi-angle-left" onClick={menos} ></button>
                <button class="boton_navegacion pi pi-angle-right" onClick={mas} ></button>
            </div>
        </div>
        <div class="contenedor">
            <div class="contenedor_centrado"> 
                {recetas.map((receta) => <ComponenteReceta  value={receta} onSeleccion={seleccionar}/>)}
            </div>
        </div>
        <DialogReceta visible={visible} setVisible={setVisible} receta={recetaElegida}/>
    </div>
  );
}