import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
export default function DialogReceta(props) {
    const nombre = props.receta.nombre?props.receta.nombre:"";
    const descripcion = props.receta.descripcion?props.receta.descripcion:"";
    const ingredientes = props.receta.ingredientes?props.receta.ingredientes:[];
    const foto = props.receta.foto?props.receta.foto:[];
    const porciones = props.receta.porciones?props.receta.porciones:'';
    const palabraClaves = props.receta.palabraClaves?props.receta.palabraClaves:[];
    const hc = props.receta.porciones && props.receta.carbohidratos ? Number((props.receta.carbohidratos/props.receta.porciones).toFixed(0)):0;
    const link = props.receta.url?<a href={props.receta.url} target="_blank">Ver receta original</a>:'';
    return (
        <Dialog header={nombre} visible={props.visible} maximizable  style={{ width: '50vw' }} 
                onHide={() => props.setVisible(false)}
                breakpoints={{ '960px': '75vw', '641px': '100vw' }} footer={link}> 
        
        <div class="contenedor_centrado_dialog">
            <div class="tarjeta"> 
                <Image src={foto} alt="Image" width="350" />
                <div class="comentario">{porciones} porciones de {hc} Carbohidratos cada una </div>
            </div>
            <div class="tarjeta"> 
                <div class="comentario">Tags: {palabraClaves.map((palabraClave) =><span>{ palabraClave.palabra } - </span>)} </div>
                <div class="titulo">Lista de Ingredientes:</div>
                <ul>
                    {ingredientes.map((step)=><li>{step.cantidadTotal} {step.unidadMedida.unidadMedida!='unidad'?step.unidadMedida.unidadMedida+'s':''} - {step.ingrediente} ({step.medidaCasera})</li>)}
                </ul>
            </div>
        </div>
        <p></p>
        <div class="contenedor_centrado_pasos">
            <div class="titulo">Elaboración paso a paso:</div>
            {descripcion.split('\n').map((item)=><div>{item}</div>)}
            
        </div>
    </Dialog>
    );
}